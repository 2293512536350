import React, { useEffect, useState } from 'react';
import formData from 'form-data';
import Mailgun from 'mailgun.js';

import axios from 'axios';
import { navigate } from 'gatsby';
import { Form, Input, Button, Row, Col, message, Typography } from 'antd';
import { RiMailSendLine } from 'react-icons/ri';

import SEO from '../blocks/layout/seo';
import { Firebase } from '../config/firebase';
import AppContainer from '../blocks/layout';

const validateMessages = {
      required: '${label} is required!',
      types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
      },
      number: {
            range: '${label} must be between ${min} and ${max}',
      },
};

const { Text } = Typography;

export default function Support() {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false);
      const [userStatus, setUserStatus] = useState('free');

      useEffect(() => {
            async function fetchSettings(user) {
                  const response = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/user/${user.uid}`
                  );
                  if (response.data.notFound !== undefined) {
                        navigate('/setup');
                  } else {
                        const { email, cloud_name, status } = response.data;
                        setUserStatus(status[0]);
                        form.setFieldsValue({
                              username: cloud_name,
                              email,
                        });
                  }
            }
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        fetchSettings(user);
                  }
            });
      }, []);

      const onFinish = async (values) => {
            setLoading(true);
            values.status = userStatus;
            const mailgun = new Mailgun(formData);
            const mg = mailgun.client({username: 'api', key: process.env.GATSBY_MAILGUN_API_KEY });
            const data = {
                  from: "NoCodeAPI <hi@nocodeapi.com>",
                  to: "hello@nocodeapi.com",
                  subject: "Support email from NoCodeAPI",
                  template: "support_email_template",
                  "h:Reply-To": values.email,
                  'h:X-Mailgun-Variables': JSON.stringify({cloud_name: values.username, subscription: values.status, query: values.query})
            };
            const response = await mg.messages.create(process.env.GATSBY_MAILGUN_DOMAIN, data);
            if (response.status === 200) {
                  form.resetFields();
                  message.success('We got your valuable feedback! Will get back to you soon in your email.');
                  setLoading(false);  
            } else {
                  message.error('Something is wrong! Please drop us email on hello@nocodeapi.com');
                  setLoading(false);   
            }
      };
      return (
            <>
                  <SEO title="Support — NoCodeAPI" />
                  <AppContainer activeNav="settings">
                        <div className="page-content-header">
                              <h3>Send us your query.</h3>
                        </div>
                        <Row>
                              <Col md={10}>
                                    <Form
                                          form={form}
                                          layout="vertical"
                                          name="nest-messages"
                                          onFinish={onFinish}
                                          validateMessages={validateMessages}
                                    >
                                          <Form.Item
                                                name="username"
                                                label="Cloud Name"
                                                rules={[{ required: true }]}
                                          >
                                                <Input disabled />
                                          </Form.Item>
                                          <Form.Item
                                                name="email"
                                                label="Email Address"
                                                rules={[
                                                      {
                                                            type: 'email',
                                                            required: true,
                                                      },
                                                ]}
                                          >
                                                <Input />
                                          </Form.Item>
                                          <Form.Item
                                                name="query"
                                                rules={[{ required: true }]}
                                                label="Query"
                                          >
                                                <Input.TextArea placeholder="Your query" />
                                          </Form.Item>
                                          <Form.Item>
                                                <Button
                                                      loading={loading}
                                                      icon={
                                                            <RiMailSendLine
                                                                  style={{
                                                                        marginRight: 8,
                                                                  }}
                                                            />
                                                      }
                                                      type="primary"
                                                      htmlType="submit"
                                                      style={{
                                                            fontSize: 13,
                                                            fontWeight: 400,
                                                            display: 'flex',
                                                            alignItems:
                                                                  'center',
                                                      }}
                                                >
                                                      Send query
                                                </Button>
                                          </Form.Item>
                                    </Form>
                                    <p
                                          style={{
                                                color: '#606060',
                                                fontSize: 13,
                                          }}
                                    >
                                          You will get response back within 12
                                          hours.
                                    </p>
                              </Col>
                              <Col md={4} />
                              <Col md={10} className="support-links">
                                    <br />
                                    <Text>Email</Text>
                                    <br />
                                    <a href="mailto:hello@nocodeapi.com">
                                          hello@nocodeapi.com
                                    </a>
                                    <br />
                                    <br />

                                    <Text>Twitter</Text>
                                    <br />
                                    <a
                                          href="https://twitter.com/nocodeapi"
                                          target="_blank"
                                    >
                                          https://twitter.com/nocodeapi
                                    </a>
                                    <br />
                                    <br />

                                    <Text>Telegram</Text>
                                    <br />
                                    <a
                                          href="https://t.me/NoCodeAPI"
                                          target="_blank"
                                    >
                                          https://t.me/NoCodeAPI
                                    </a>
                              </Col>
                        </Row>
                  </AppContainer>
            </>
      );
}
